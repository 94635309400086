
import { Options, Vue } from 'vue-class-component';
import { Emit, Provide, Watch } from 'vue-property-decorator';
import { platformManagement, commonController, platformSave} from '../../api/platform';
import { message } from 'ant-design-vue/es';
import { throttle } from '../../untils/until';
interface FormState {
  nsrmc: string;
  nsrsbh: string;
  hy: string;
  zgswskfj: string;
  nsrzt: string;
  zcdz: string;
  zcdzxzqh: string;
  zgswjg: string;
  fddbrxm: string;
  scjydzxzqh: string;
  jdxz: string;
  scjydz: string;
}
interface ownerList {
  pageNum: number;
  pageSize: number;
  name: string;
  type: number;
}
/**
 * Greeter类，公告查询
 * @constructor
 * @return {*}
 */
class Greeter {
    val: string;
    index: number;
    constructor(message: string,type: number) {
      this.val = message;
      this.index = type
    }
    greet() {
       const data:any = {
         pageNum: 1,
         pageSize: 100,
         name: this.val,
         type: this.index
       }
      return commonController(data)
    }
}
@Options({
  components: {
  },
})
export default class OwnerManagement extends Vue {
   @Provide() columns: any
   rules: any = {
      nsrmc: [
        { required: true, message: '请输入纳税人名称', trigger: 'blur' },
      ],
      nsrsbh: [
        { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
      ],
      hy: [
        { required: true, message: '请选择行业', trigger: 'blur' },
      ],
      nsrzt: [
         { required: true, message: '请输入纳税人状态', trigger: 'blur' },
      ],
      bsrdzxx: [
         { pattern: /^([a-zA-Z]|[0-9])(\w|)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: '请输入合法的邮箱',trigger: 'blur' },
      ],
      fddbrdzxx:[
         { pattern: /^([a-zA-Z]|[0-9])(\w|)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: '请输入合法的邮箱',trigger: 'blur' },
      ]
   }  
  formState:any = {
    nsrzt:"正常"
  }
  //验证数据
  verifyStatedOptions:any = [];
  //证件类型
  personCertificate:any = [];
  //主管税务所
  taxOffice:any = [];
  taxOfficeQu:any =[];
  visibleTitle:boolean = false;
  @Emit()
/**
 * handleSearch类，行业查询
 * shipper接口参数限制
 * @constructor
 * @return {*}
 */
  handleSearch(val:any): void{
    const data:ownerList={
       pageNum: 1,
       pageSize: 100,
       name: val,
       type: 1
    }
    commonController(data).then((res: any) => {
       this.verifyStatedOptions = res.data.list
    })
  }
 /**
 * Greeter类，查询
 * handleSearchCertificate方法，主管税务局接口查询
 * @constructor
 * @return {*}
 */
  handleSearchCertificate(val:any): void{
    let handCommon= new Greeter(val,3);
    handCommon.greet().then((res:any)=>{
      this.taxOffice = res.data.list
    })
  }

   /**
 * Greeter类，查询
 * handleSearchCertificate方法，主管税务局接口查询
 * @constructor
 * @return {*}
 */
  handleSearchCertificateQu(val:any): void{
    let handCommon= new Greeter(val,2);
    handCommon.greet().then((res:any)=>{
      this.taxOfficeQu = res.data.list
    })
  }
  handleOk():void {
    throttle(this.setHandleOk,1000)()
  }
  setHandleOk():void{
     platformSave(this.formState).then((res:any)=>{
       if(res.code==200) {
         message.success(res.message);
      }
      this.visibleTitle = false
    })
  }

   handleChange(value: string):void {
     this.formState.scjydzxzqh = value
   }

  handleChange1(value: string):void {
     this.formState.zgswjg = value
   }
   handleChange2(value: string):void {
     this.formState.zcdzxzqh = value
   }
   handleChange3(value: string):void {
     this.formState.zgswskfj = value
   }
  
/**
 * save方法，平台信息保存
 * @constructor
 * @return {*}
 */
  save():void {
    this.visibleTitle = true
  }

  mounted() {
    platformManagement().then((res: any) =>{
      this.formState = res.data
    })
    const data:ownerList={
       pageNum: 1,
       pageSize: 100,
       name: "",
       type: 1
    }
    commonController(data).then((res: any) => {
      this.verifyStatedOptions = res.data.list
    })

    //居民身份证类型
    let handCommon= new Greeter("",4);
    handCommon.greet().then((res:any)=>{
      this.personCertificate = res.data.list
    })
  }
}
